'use strict';

// Import Libraries
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import UrlQueryParser from './url-query-parser';

// -----------------------------------------------------------
//  NUps
// -----------------------------------------------------------

const NUps = ({collection, onUrlFiltersChange}) => {
  const [loaded, setloaded] = useState(false);
  useEffect(() => {
    loadImages();
  }, []);

  const handleClick = (e, url) => {
    const a = document.createElement('a');
    a.href = url;
    if (a.pathname === location.pathname && a.search) {
      // link is to the current page
      e.preventDefault();

      const urlQueryParser = new UrlQueryParser(a.href);
      onUrlFiltersChange(urlQueryParser.filters());
    }
  };

  const attachSlick = () => {
    $('.n-ups > div').slick({
      // generic settings
      arrows: false,
      infinite: true,
      speed: 300,
      dots: collection.length > 10,


      // xl - wide desktops
      slidesToShow: 10,
      slidesToScroll: 10,

      responsive: [{
        // lg - desktops
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          dots: collection.length > 8,
        }
      }, {
        // md - tablets
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: collection.length > 3,
        }
      }, {
        // sm - new phones
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: collection.length > 3,
        }
      }, {
        // xs - old phones
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: collection.length > 3,
        }
      }]
    });
  };

  const loadImages = async () => {
    const promises = [];
    promises.push((collection || []).forEach((item, i) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'use-credentials';
        image.onload = () => {                    
          resolve
        };
        image.onerror = reject;
        image.src = item.image;
      });
    }));
    await Promise.all(promises);
    setloaded(true);
    attachSlick();
  };

  if (!loaded) return null;

  return (
    <div className={classNames("n-ups", collection.length < 6 ? 'text-center' : 'text-left')}>
      <div>
        {
          (collection || []).map((item, i) => (
            <a
              key={item.url + i}
              className="n-up"
              href={item.url}
              role="link"
              onClick={(e) => handleClick(e, item.url)}
            >
              <img
                alt=""
                role="presentation"
                src={item.image}
                className="img-fluid"
              />
              <div className="title text-center">{item.title}</div>
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default NUps;
